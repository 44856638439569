import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import Loader from "../../components/Loader";
import {
    Box,
    Tabs,
    Tab,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Grid as MuiGrid,
    Typography,
    Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import api from "../../api/api";
import CustomTabPanel from "../../components/CustomTabPanel";
import { Add as AddIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Edit from "@material-ui/icons/Edit";

const QuillWrapperTop = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Wrapper = styled("div")`
  padding: 0 10px;
  background-color: white;
`;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    createButton: {
        color: "white",
        padding: "8px 20px",
        background: "#7FB741",
        "&:hover": {
            background: "#8AC747",
        },
    },
}));

function GettingStarted() {
    const auth = useSelector((state) => state.authReducer);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = useState(null);
    const [value, setValue] = React.useState(0);
    const [gettingStarteds, setGettingStarteds] = useState([]);
    const [showCreateGettingStarted, setshowCreateGettingStarted] = useState(
        false
    );
    const classes = useStyles();
    const history = useHistory();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleShowCreateGettingStarted = () => {
        history.push("/creategettingstarted");
    };

    const handleEdit = (item) => {
        history.push(`/gettingstarteddetail/${item.id}`);
    };

    useEffect(() => {
        api
            .get(`api/GettingStarted/All?v=1.0`, {
                headers: {
                    Authorization: `Bearer ${auth.user?.token}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setGettingStarteds(response.data.data);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message);
            });
    }, []);

    if (isLoading) return <Loader />;
    if (error) return <div style={{ color: "red" }}>{error}</div>;

    return (
        <React.Fragment>
            <Helmet title="GettingStarted" />

            <Grid justify="space-between" container spacing={4}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Getting Started
                    </Typography>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Typography>Pages</Typography>
                        <Typography>Getting Started</Typography>
                    </Breadcrumbs>
                </Grid>
                {auth?.user?.rol && auth?.user?.rol.startsWith("Bromcom") && (
                    <>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleShowCreateGettingStarted}
                                disabled={showCreateGettingStarted}
                                className={classes.createButton}
                            >
                                <AddIcon />
                                Add Tab
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>

            <Divider my={6} />

            <Wrapper>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                        {gettingStarteds.map((item, index) => (
                            <Tab
                                label={
                                    <>
                                        {item.title + " "}
                                        {auth?.user?.rol && auth?.user?.rol.startsWith("Bromcom") && (
                                            <>
                                                <Edit
                                                    onClick={() => {
                                                        handleEdit(item);
                                                    }}
                                                ></Edit>
                                            </>
                                        )}
                                    </>
                                }
                                {...a11yProps(index)}
                                key={"Tab" + item.id}
                            ></Tab>
                        ))}
                    </Tabs>
                    {gettingStarteds.map((item, index) => (
                        <CustomTabPanel
                            value={value}
                            index={index}
                            id={item.id}
                            key={item.id}
                        >
                            <QuillWrapperTop>
                                <ReactQuill value={item.content} theme="bubble" readOnly={true} />
                            </QuillWrapperTop>
                        </CustomTabPanel>
                    ))}
                </Box>
            </Wrapper>
        </React.Fragment >
    );
}

export default GettingStarted;
