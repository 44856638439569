import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import IconButton from "@material-ui/core/IconButton";

import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import Moment from "react-moment";

import { setPartner } from "../../redux/actions/partnerActions";

// const PartnerRequest = async(() => import("../../pages/pages/PartnerRequest"));
import PartnerRequest from "../../pages/pages/PartnerRequest";

import QuickSearchToolbar from "./QuickSearchToolbar";
import CustomDialog from "../CustomDialog";

const Badge = styled.span`
  font-size: 11px;
  background-color: ${(props) => props.color};
  color: white;
  border-radius: 8px;
  padding: 4px 8px;
  text-align: center;
`;

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export default function QuickFilteringGrid({ partners }) {
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState("");

    const [rows, setRows] = useState(partners);

    const [open, setOpen] = useState(false);

    const [sortModel, setSortModel] = useState([
        {
            field: "createdDate",
            sort: "desc",
        },
    ]);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");

        const filteredRows = partners.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]?.toString());
            });
        });

        setRows(filteredRows);
    };

    useEffect(() => {
        setRows(partners);
    }, [partners]);

    const handleClose = () => {
        setOpen(false);
    };

    const cols = [
        {
            field: "organizationName",
            headerName: "Organisation Name",
            flex: 1,
            minWidth: 150,
            filterable: false,
        },
        {
            field: "firstName",
            headerName: "First Name",
            flex: 1,
            minWidth: 150,
            filterable: false,
        },
        {
            field: "lastName",
            headerName: "Last Name",
            flex: 1,
            minWidth: 150,
            filterable: false,
        },
        {
            field: "createdDate",
            headerName: "Created Date",
            flex: 1,
            minWidth: 150,
            filterable: false,
            renderCell: (params) => (
                <Moment date={params.value} format="DD/MM/YYYY HH:mm:ss" />
            ),
        },
        {
            field: "partnerStatus",
            headerName: "Status",
            width: 110,
            align: "center",
            valueOptions: [
                { label: 'New', value: 'New' },
                { label: 'Open', value: 'Open' },
                { label: 'Accepted', value: 'Accepted' },
                { label: 'Rejected', value: 'Rejected' }
            ],
            filterOperators: getGridSingleSelectOperators().filter(
                (operator) => operator.value === 'is'
            ),
            renderCell: (params) => {
                if (params.value === "Open")
                    return <Badge color="#54AAD1">{params.value}</Badge>;
                else return <Badge color="#EF233C">{params.value}</Badge>;
            },
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            width: 110,
            align: "center",
            filterable: false,
            renderCell: (params) => {
                const onClick = () => {
                    dispatch(
                        setPartner(partners.find((partner) => partner.id === params.row.id))
                    );
                    setOpen(true);
                };
                return (
                    <>
                        <IconButton onClick={onClick}>
                            <MoreVertIcon> Click</MoreVertIcon>
                        </IconButton>

                        {open && (
                            <CustomDialog open={open} handleClose={handleClose}>
                                <PartnerRequest handleClose={handleClose} />
                            </CustomDialog>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <div style={{ height: 760, width: "100%" }}>
            <DataGrid
                initialState={{
                    filter: {
                        filterModel: {
                            items: [{
                                columnField: "partnerStatus",
                                operatorValue: "is",
                                value: "Open"
                            }],
                        }
                    }
                }}
                sx={{
                    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
                        outline: "none",
                    },
                    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
                        outline: "none",
                    },
                }}
                components={{ Toolbar: QuickSearchToolbar }}
                rows={rows}
                columns={cols}
                sortModel={sortModel}
                rowsPerPageOptions={[10, 50, 100]}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event) => requestSearch(event.target.value),
                        clearSearch: () => requestSearch(" "),
                    },
                }}
                onSortModelChange={(model) => setSortModel(model)}
                disableSelectionOnClick
                scrollbarSize={30}
                onRowDoubleClick={(e) => {
                    dispatch(
                        setPartner(partners.find((partner) => partner.id === e.row.id))
                    );
                    setOpen(true);
                }}
            />
        </div>
    );
}
